<template>
  <v-card>
    <v-card-title>
      {{ $t("plan.template-management") }}

    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="templateHeaders"
        :items="templates"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-col>
              <v-file-input
                v-model="uploadFiles[item.value]"
                :label="$t('plan.upload-new')"
                accept=".docx"
                hide-details
                dense
                outlined
                prepend-icon=""
                @change="prepareUpload(item.value)"
              ></v-file-input>
            </v-col>
            <v-col class="d-flex align-center">
              <v-btn
                color="primary"
                small
                :disabled="!uploadFiles[item.value]"
                @click="uploadTemplate(item.value)"
              >
                <v-icon left>mdi-upload</v-icon>
                {{ $t("plan.upload") }}
              </v-btn>
              <v-btn
                color="error"
                small
                class="ml-2"
                @click="resetTemplate(item.value)"
              >
                <v-icon left>mdi-refresh</v-icon>
                {{ $t("plan.reset") }}
              </v-btn>
              <v-btn
                color="success"
                small
                class="ml-2"
                @click="downloadTemplate(item.value)"
              >
                <v-icon left>mdi-download</v-icon>
                {{ $t("plan.download-custom-template") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog_message" max-width="500px">
      <v-card>
        <v-card-title>{{ message.title }}</v-card-title>
        <v-card-text>{{ message.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_message = false">{{ $t("common.confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  name: "TemplateManagement",
  data: () => ({
    search: "",
    dialog_message: false,
    message: {
      title: "",
      text: "",
    },
    loading: false,
    templates: [
      {text: "新北市自行組織更新會同意書", value: "新北市自行組織更新會同意書"},
      {text: "台北市都市更新事業計畫同意書", value: "台北市都市更新事業計畫同意書"},
      {text: "台北市事業概要同意書", value: "台北市事業概要同意書"},
    ],
    uploadFiles: {},
    templateHeaders: [
      { text: 'plan.template-name', value: 'text' },
      { text: 'plan.actions', value: 'actions', sortable: false }
    ]
  }),
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  created() {
    // Translate headers
    this.templateHeaders = this.templateHeaders.map(header => {
      return {
        ...header,
        text: this.$t(header.text)
      };
    });
  },
  methods: {
    prepareUpload(templateName) {
      // This method is called when a file is selected for a specific template
      // No need to do anything here, the v-model binding will update uploadFiles[templateName]
    },
    
    uploadTemplate(templateName) {
      if (!this.uploadFiles[templateName]) {
        this.message = {
          title: this.$t("common.warning"),
          text: this.$t("plan.template-required-fields"),
        };
        this.dialog_message = true;
        return;
      }
      
      const formData = new FormData();
      formData.append('template_file', this.uploadFiles[templateName]);
      formData.append('template_name', templateName);
      
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/upload_templates/`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + this.token,
        },
      };
      
      this.loading = true;
      
      this.axios.post(url, formData, config)
        .then(response => {
          this.message = {
            title: this.$t("common.warning"),
            text: this.$t("plan.template-upload-success"),
          };
          this.dialog_message = true;
          // Clear the file input
          this.$set(this.uploadFiles, templateName, null);
        })
        .catch(error => {
          let errorMessage = this.$t("plan.template-upload-error");
          if (error.response && error.response.data && error.response.data.error_message) {
            errorMessage = error.response.data.error_message;
          }
          
          this.message = {
            title: this.$t("common.warning"),
            text: errorMessage,
          };
          this.dialog_message = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    resetTemplate(templateName) {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/reset_templates/`;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.token,
        },
      };
      
      this.loading = true;
      
      this.axios.post(url, { template_name: templateName }, config)
        .then(response => {
          this.message = {
            title: this.$t("common.warning"),
            text: this.$t("plan.template-reset-success"),
          };
          this.dialog_message = true;
        })
        .catch(error => {
          let errorMessage = this.$t("plan.template-reset-error");
          if (error.response && error.response.data && error.response.data.error_message) {
            errorMessage = error.response.data.error_message;
          }
          
          this.message = {
            title: this.$t("common.warning"),
            text: errorMessage,
          };
          this.dialog_message = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    downloadTemplate(templateName) {
      // Create the download URL with the template name as a query parameter
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/download_template/?template_name=${encodeURIComponent(templateName)}`;
      
      // Set the authorization header
      const config = {
        headers: {
          'Authorization': 'Token ' + this.token,
        },
        responseType: 'blob', // Important for downloading files
      };
      
      this.loading = true;
      
      this.axios.get(url, config)
        .then(response => {
          // Create a blob from the response data
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });
          
          // Get filename from Content-Disposition header if available
          let filename = `${templateName}.docx`;
          const contentDisposition = response.headers['content-disposition'];
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="(.+)"/i);
            if (filenameMatch && filenameMatch[1]) {
              filename = filenameMatch[1];
            }
          }
          
          // Create a link element and trigger the download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          
          // Clean up
          window.URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          let errorMessage = this.$t("plan.template-download-error");
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage = this.$t("plan.no-custom-template");
            } else if (error.response.data && error.response.data.error_message) {
              errorMessage = error.response.data.error_message;
            }
          }
          
          this.message = {
            title: this.$t("common.warning"),
            text: errorMessage,
          };
          this.dialog_message = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
